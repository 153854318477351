import React, { Fragment, useState } from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { NavLink } from 'react-router-dom';
import { passwordStrength } from 'check-password-strength';

import { createPassword } from '../store/modules/auth';
import ModalSuccessCreatePassword from '../components/modals/ModalSuccessCreatePassword';

const CreatePasswordPage = ({ createPassword }) => {
  const { siret, clientPriosId, token } = useParams();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const required = (value) => (value ? undefined : 'Champ requis !');

  const handleSubmit = (values) => {
    const data = values;
    data.authorization_token = token;
    data.reset_token = token;
    data.siret = String(data.siret);
    data.client_prios_id = Number(clientPriosId);

    const isSecurePassword = () => {
      const strength = passwordStrength(data.password);
      return (
        strength.length >= 8 &&
        strength.contains.includes('lowercase') &&
        strength.contains.includes('uppercase') &&
        strength.contains.includes('symbol')
      );
    };

    if (data.password === data.password_repeat) {
      if (isSecurePassword()) {
        return createPassword(data)
          .then(() => {
            setError(null);
            setSuccess(true);
          })
          .catch((err) => setError(err));
      } else {
        setSuccess(false);
        setError(
          "Votre mot de passe n'est pas assez sécurisé ! (il doit comporter : au minimum 8 caractères, au moins 1 majuscule, au moins 1 minuscule, au moins 1 caractère spécial)"
        );
      }
    } else {
      setSuccess(false);
      setError('Vos mots de passe ne sont pas identiques !');
    }
  };

  return (
    <div className="authentication forgot-password">
      <div className="authentication-block">
        <div className="logo-block">
          <img src={require(`../../assets/images/logo-terdici-collectes.svg`)} />
        </div>
        <div className="box">
          <div className="box-header">
            <div className="box-header-actions">
              <NavLink to="/" className="btn">
                <i className="far fa-chevron-left"></i>
              </NavLink>
            </div>
            <div className="box-header-title">Définir mon mot de passe</div>
          </div>
          <div className="box-content">
            {success && <ModalSuccessCreatePassword />}

            <Form
              onSubmit={handleSubmit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="siret"
                    validate={required}
                    component="input"
                    className="field"
                    initialValue={(siret && siret) || ' '}
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="number" inputMode="numeric" disabled={true} />
                        <label>Siret</label>
                      </div>
                    )}
                  </Field>

                  <Field validate={required} name="password" component="input" className="field">
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input
                          {...input}
                          type="password"
                          onChange={(value) => {
                            setError(null);
                            input.onChange(value);
                          }}
                        />
                        <label>Mot de passe *</label>
                      </div>
                    )}
                  </Field>
                  <Field
                    validate={required}
                    name="password_repeat"
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input
                          {...input}
                          type="password"
                          onChange={(value) => {
                            setError(null);
                            input.onChange(value);
                          }}
                        />
                        <label>Confirmez votre mot de passe *</label>
                      </div>
                    )}
                  </Field>

                  {error && (
                    <div className="error-block">
                      <span className="error-icon">
                        <i className="fas fa-exclamation" />
                      </span>
                      <div className="error-message">{error}</div>
                    </div>
                  )}

                  <button type="submit" className="btn btn-primary">
                    <span>Valider</span>
                  </button>
                  <NavLink className="btn-link" to="/connexion">
                    Revenir à la page de connexion
                  </NavLink>
                </form>
              )}
            />
          </div>
        </div>
      </div>
      <div className="authentication-overflow"></div>
    </div>
  );
};

CreatePasswordPage.propTypes = {
  createPassword: PropTypes.func.isRequired
};

export default connect(null, { createPassword })(CreatePasswordPage);
