import React from 'react';
import PropTypes from 'prop-types';

import { TYPES_CONTRATS } from '../../constants/Properties';

import moment from 'moment';
import fr from 'moment/locale/fr';
moment.locale('fr', fr);

const CardContrat = ({ contrat }) => {
  return (
    <div className="contrat">
      <div className="contrat-infos">
        <span className="contrat-date">
          {(contrat.date_engagement && moment(contrat.date_engagement).format('DD/MM/YYYY')) || ''}
        </span>
        <span className="contrat-tonnage">{Number(contrat.quantite).toFixed(2)} t</span>
      </div>
      <div className="contrat-infos">
        <span className="contrat-type">{TYPES_CONTRATS[contrat.type]}</span>
        <span className="contrat-cours">
          {(contrat.prix && contrat.prix !== 0 && `${contrat.prix} €/t`) || ''}
        </span>
      </div>
    </div>
  );
};

CardContrat.propTypes = {
  contrat: PropTypes.object.isRequired
};

export default CardContrat;
