import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setVente, setCour } from '../../store/modules/ui';

import moment from 'moment';
import fr from 'moment/locale/fr';
moment.locale('fr', fr);

import { getTendanceClass } from '../../utils/methodes';

const CerealeCard = ({ setCour, cour, user, setVente, vente }) => {
  const prix =
    cour.cours_actuel + ((user && user.price_zone_maj_min && user.price_zone_maj_min) || 0);
  const initVente = () => {
    setCour(cour);
    setVente({
      cereale_id: cour && cour.cereale.cereale_prios_id,
      open_modal_vente: true,
      open_modal_confirm: false,
      open_modal_success: false,
      cgu: false,
      nom_cereale: cour.cereale.libelle,
      data: {
        client_prios_id: user && user.client_prios_id,
        cereale_prios_id: cour.cereale.cereale_prios_id,
        quantite: '',
        type: 'VENTE',
        prix: prix,
        cours_actuel: prix,
        mode_livraison: '',
        annee_collecte: cour.annee_collecte
      }
    });
  };

  return (
    <div
      className={`card-cereal${cour && cour.suspendu === false ? '' : ' cotation-suspendu'}${
        cour && cour.cours_actuel
          ? ' cotation-' +
            getTendanceClass(
              cour.cours_actuel +
                ((user && user.price_zone_maj_min && user.price_zone_maj_min) || 0),
              cour.ancien_cours +
                ((user && user.price_zone_maj_min && user.price_zone_maj_min) || 0)
            )
          : ''
      }`}
    >
      <div className="card-cereal-infos">
        <div className="card-cereal-title">{cour && cour.cereale && cour.cereale.libelle}</div>

        {cour && cour.suspendu === false ? (
          <Fragment>
            <div className="card-cereal-cotation">
              <div className="card-cereal-cotation-icon">
                {getTendanceClass(cour.cours_actuel, cour.ancien_cours) == 'equal' ? (
                  <i className={`fa-solid fa-arrow-right`} />
                ) : (
                  <i
                    className={`fa-solid fa-arrow-trend-${getTendanceClass(
                      cour.cours_actuel +
                        ((user.price_zone_maj_min && user.price_zone_maj_min) || 0),
                      cour.ancien_cours +
                        ((user.price_zone_maj_min && user.price_zone_maj_min) || 0)
                    )}`}
                  />
                )}
              </div>
              <div className="">
                <div className="card-cereal-cotation-value">
                  {cour.cours_actuel + (user.price_zone_maj_min && user.price_zone_maj_min) || 0}
                  &nbsp;€/Tonne
                </div>
                <div className="card-cereal-cotation-maj">
                  maj {moment(cour.updated_at).format('DD/MM/YYYY HH:mm')}
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="card-cereal-cotation">
            <div className="card-cereal-cotation-icon">
              <i className="fa-solid fa-ban" />
            </div>
            <div className="card-cereal-cotation-value">Cotation suspendue</div>
          </div>
        )}
      </div>
      <div className="card-cereal-actions">
        {cour && !cour.suspendu && (
          <button
            disabled={cour && cour.suspendu}
            onClick={() => {
              initVente();
            }}
            className="btn"
          >
            <i className="fas fa-truck-container" />
            <span>Vendre</span>
          </button>
        )}
      </div>
    </div>
  );
};

CerealeCard.propTypes = {
  cour: PropTypes.object.isRequired,
  setVente: PropTypes.func.isRequired
};

export default connect(
  (state, props) => ({
    user: state.auth.utilisateur,
    vente: state.data.entities && state.data.entities.ui.vente
  }),
  { setVente, setCour }
)(CerealeCard);
