import React, { useEffect } from 'react';
import { withRouter, BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { requireAuthentication } from '../components/hoc/AuthenticatedComponent';

import PasswordOubliePage from './auth/PasswordOubliePage';
import CreatePasswordPage from './CreatePasswordPage';
import LoginPage from './auth/LoginPage';

import Home from './Home';

import Contrats from './contrats/Contrats';
import Contrat from './contrats/Contrat';

import Contacts from './Contacts';
import MonCompte from './MonCompte';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const Router = function () {
  return (
    <BrowserRouter>
      <Switch>
        {/* Route avec authentification test */}
        <Route exact path="/connexion" component={LoginPage} />
        <Route exact path="/" component={requireAuthentication(Home)} />
        <Route exact path="/contrats" component={requireAuthentication(Contrats)} />
        <Route exact path="/contrats/:cerealeId" component={requireAuthentication(Contrat)} />
        <Route exact path="/contacts" component={requireAuthentication(Contacts)} />
        <Route exact path="/compte" component={requireAuthentication(MonCompte)} />
        {/* Fin des Routes avec authentification */}
        <ScrollToTop>
          <Route
            exact
            path="/definir-mot-de-passe/:siret?/:clientPriosId?/:token?"
            component={CreatePasswordPage}
          />
          <Route exact path="/mot-de-passe-oublie" component={PasswordOubliePage} />
          {/* Par default, on envoie la page sur login */}
        </ScrollToTop>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
