import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Toggle from 'react-toggle';

const CerealeSelectCard = ({ cereale, isSelected, onChangeCereale }) => {
  return (
    <Fragment>
      <div className="toggle-field">
        <div className="toggle-label">{cereale.libelle}</div>
        <Toggle
          checked={isSelected}
          name="cerealeSelectionnee"
          value="yes"
          onChange={() => onChangeCereale(!isSelected)}
        />
      </div>
    </Fragment>
  );
};

CerealeSelectCard.propTypes = {
  cereale: PropTypes.object.isRequired,
  onChangeCereale: PropTypes.func.isRequired,
  isSelected: PropTypes.bool
};
export default CerealeSelectCard;
