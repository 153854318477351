import React from 'react';
import { map } from 'lodash';

const SwitcherValue = ({ options, activeOption, onChangeValue }) => {
  return (
    <div className="toggle-year">
      {map(options, (o, i) => (
        <div
          onClick={() => onChangeValue(o.value)}
          key={i}
          className={`toggle-data ${activeOption === o.value ? 'active' : ''}`}
        >
          {o.label}
        </div>
      ))}
    </div>
  );
};

export default SwitcherValue;
