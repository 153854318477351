import React from 'react';

import PropTypes from 'prop-types';

const ContactCard = ({ contact }) => {
  return (
    <div className="card-contact">
      <div className="card-main_infos">
        <div className="card-infos">
          <div className="card-data">
            <div className="card-data-value">{contact.titre}</div>
            <div className="card-data-label">{contact.sous_titre}</div>
          </div>
          {contact && contact.adresse && (
            <div className="card-data">
              <i className="fas fa-map-marker-alt" />
              <div className="card-data-value">{contact.adresse}</div>
            </div>
          )}

          {contact && contact.telephone_1 && (
            <a href={`tel:${contact.telephone_1}`} target="_blank" className="card-data">
              <i className="fas fa-phone-alt" />
              <div className="card-data-value">{contact.telephone_1}</div>
            </a>
          )}
          {contact && contact.telephone_2 && (
            <a href={`tel:${contact.telephone_2}`} target="_blank" className="card-data">
              <i className="fas fa-phone-alt" />
              <div className="card-data-value">{contact.telephone_2}</div>
            </a>
          )}

          {contact && contact.email && (
            <a href={`mailto:${contact.email}`} target="_blank" className="card-data">
              <i className="fas fa-envelope" />
              <div className="card-data-value">{contact.email}</div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

ContactCard.propTypes = {
  contact: PropTypes.object.isRequired
};
export default ContactCard;
