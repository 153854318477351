import { flow, set } from 'lodash/fp';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_CONTACTS_REQUEST = 'tdc/FETCH_ALL_CONTACTS_REQUEST';
const FETCH_ALL_CONTACTS_SUCCESS = 'tdc/FETCH_ALL_CONTACTS_SUCCESS';
const FETCH_ALL_CONTACTS_FAILURE = 'tdc/FETCH_ALL_CONTACTS_FAILURE';

export const contactsActionsHandlers = {
  [FETCH_ALL_CONTACTS_REQUEST]: (state) =>
    flow(set('loaded.contacts', false), set('loading.contacts', true))(state),
  [FETCH_ALL_CONTACTS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.contacts', action.response.entities.contacts || {}),
      set('loaded.contacts', true),
      set('loading.contacts', false)
    )(state);
  },
  [FETCH_ALL_CONTACTS_FAILURE]: (state) =>
    flow(set('loaded.contacts', false), set('loading.contacts', false))(state)
};

export function loadContacts(societe_id) {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_CONTACTS_REQUEST, FETCH_ALL_CONTACTS_SUCCESS, FETCH_ALL_CONTACTS_FAILURE],
      method: 'GET',
      endpoint: `/contacts/get-contacts/${societe_id}`,
      schema: Schemas.CONTACT_ARRAY,
      successMessage: 'Contacts chargés avec succès !'
    }
  };
}
