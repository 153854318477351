import React, { useState } from 'react';
import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalSuccessResetPasswordSendCompte = () => {
  const [isOpen, setIsOpen] = useState(true);

  const confirm = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      className="modal-content modal-classic small-modal"
      overlayClassName="modal-overlay"
      id="confirm-delete"
      isOpen={isOpen}
    >
      <div className="modal-body">
        Vous allez recevoir un email vous permettant de définir votre mot de passe
      </div>

      <div className="modal-footer">
        <div className="btn-group">
          <button className="btn btn-secondary" onClick={() => confirm()}>
            <span>Ok</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSuccessResetPasswordSendCompte;
