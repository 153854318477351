import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { values } from 'lodash';

import logo from '../../../assets/images/logo-terdici-collectes-blanc.svg';
import ContactCommercial from '../../components/ContactCommercial';

const MainNav = ({ commercial }) => {
  return (
    <nav className="main_nav">
      <div className="main_nav-header">
        <img className="logo" src={logo} />
      </div>
      <div className="main_nav-content">
        <ul className="main_nav-menu">
          <li>
            <NavLink exact to="/">
              <i className="far fa-home-alt"></i>
              <span>Accueil</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contrats">
              <i className="far fa-handshake-simple"></i>
              <span>Mes contrats</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contacts">
              <i className="far fa-address-book"></i>
              <span>Mes contacts</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/compte">
              <i className="far fa-user-cog"></i>
              <span>Mon compte</span>
            </NavLink>
          </li>
        </ul>

        {commercial && (
          <div className="main_nav-contact">
            <div className="main_nav-contact-title">Votre contact commercial</div>
            <ContactCommercial version="badge" commercial={commercial} />
          </div>
        )}
      </div>
    </nav>
  );
};

MainNav.propTypes = {
  utilisateur: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  utilisateur: state.auth.utilisateur,
  commercial: state.data.entities.commerciaux && values(state.data.entities.commerciaux)[0]
});

export default connect(mapStateToProps, {})(MainNav);
