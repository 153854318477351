import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import lifecycle from 'page-lifecycle';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loadContrats } from '../../store/modules/contrats';
import { loadApports } from '../../store/modules/apports';

import { map, includes, filter, orderBy, sumBy, values } from 'lodash';
import moment from 'moment';

import BaseLayout from '../shared/BaseLayout';
import ContactCommercial from '../../components/ContactCommercial';

const Contrats = ({ user, watchListCereales, loadContrats, loadApports, contrats, commercial }) => {
  useEffect(() => {
    function loadDatas() {
      loadContrats(user.client_prios_id);
      loadApports(user.code);
    }

    const listener = lifecycle.addEventListener('statechange', function (evt) {
      if (
        (lifecycle.state === 'active' || lifecycle.state === 'passive') &&
        evt.oldState !== 'active'
      ) {
        loadDatas();
      }
    });

    loadDatas();

    return () => {
      lifecycle.removeEventListener('statechange', listener);
    };
  }, []);

  const totalQuantityCereale = (cerealeId) => {
    return Number(
      sumBy(
        filter(
          contrats,
          (c) => c.cereale_prios_id === cerealeId && c.annee_collecte === moment().year()
        ),
        (c) => c.quantite
      )
    ).toFixed(2);
  };
  return (
    <BaseLayout title="Mes contrats" icon="handshake-simple">
      <div className="container">
        <div className="page-header">
          <div className="page-header-left">
            <div className="page-header-title">
              <div className="page-header-maintitle">
                Consulter l’ensemble de mes contrats actifs
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {map(orderBy(watchListCereales, ['libelle'], ['asc']), (c) => {
              return (
                <NavLink
                  className="card-cereal card-btn-sansicone"
                  to={`/contrats/${c.cereale_prios_id}`}
                  key={c.cereale_prios_id}
                >
                  <div className="card-cereal-infos">
                    <div className="card-cereal-title">{c && c.libelle}</div>
                    <div className="card-cereal-tonnage">
                      {totalQuantityCereale(c.cereale_prios_id)} Tonnes
                    </div>
                  </div>
                  <div className="card-cereal-actions">
                    <span className="btn">
                      <i className="fas fa-handshake-simple" />
                      <span>Détails</span>
                    </span>
                  </div>
                </NavLink>
              );
            })}
          </div>
        </div>

        {commercial && <ContactCommercial version="short" commercial={commercial} />}
      </div>
    </BaseLayout>
  );
};

Contrats.propTypes = {
  user: PropTypes.object.isRequired,
  loadContrats: PropTypes.func.isRequired,
  loadApports: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const listCereales = state.data.entities.cereales;

  const watchListCereales =
    state.auth &&
    state.auth.utilisateur.cereales &&
    filter(listCereales, (c) => includes(state.auth.utilisateur.cereales, c.cereale_prios_id));

  return {
    user: state.auth.utilisateur && state.auth.utilisateur,
    commercial: state.data.entities.commerciaux && values(state.data.entities.commerciaux)[0],
    contrats: state.data.entities.contrats,
    watchListCereales
  };
};

export default connect(mapStateToProps, {
  loadContrats,
  loadApports
})(Contrats);
