import { flow, set } from 'lodash/fp';

const SET_VENTE = 'tdc/SET_VENTE';
const SET_COUR = 'tdc/SET_COUR';
const CLEAR_VENTE = 'tdc/CLEAR_VENTE';

export const uiActionsHandlers = {
  [SET_VENTE]: (state, data) =>
    flow(
      set('entities.ui.vente', {
        ...state.entities.ui.vente,
        ...data
      })
    )(state),
  [SET_COUR]: (state, store) => flow(set('entities.ui.cour', store.data))(state),
  [CLEAR_VENTE]: (state) => flow(set('entities.ui.vente', {}))(state)
};

export function setVente(data) {
  return {
    type: SET_VENTE,
    ...data
  };
}
export function setCour(data) {
  return {
    type: SET_COUR,
    data
  };
}

export function clearVente() {
  return {
    type: CLEAR_VENTE
  };
}
