import { flow, set } from 'lodash/fp';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_CONTRATS_REQUEST = 'tdc/FETCH_ALL_CONTRATS_REQUEST';
const FETCH_ALL_CONTRATS_SUCCESS = 'tdc/FETCH_ALL_CONTRATS_SUCCESS';
const FETCH_ALL_CONTRATS_FAILURE = 'tdc/FETCH_ALL_CONTRATS_FAILURE';

const CREATE_CONTRAT_REQUEST = 'tdc/CREATE_CONTRAT_REQUEST';
const CREATE_CONTRAT_SUCCESS = 'tdc/CREATE_CONTRAT_SUCCESS';
const CREATE_CONTRAT_FAILURE = 'tdc/CREATE_CONTRAT_FAILURE';

export const contratsActionsHandlers = {
  [FETCH_ALL_CONTRATS_REQUEST]: (state) =>
    flow(set('loaded.contrats', false), set('loading.contrats', true))(state),
  [FETCH_ALL_CONTRATS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.contrats', action.response.entities.contrats || {}),
      set('loaded.contrats', true),
      set('loading.contrats', false)
    )(state);
  },
  [FETCH_ALL_CONTRATS_FAILURE]: (state) =>
    flow(set('loaded.contrats', false), set('loading.contrats', false))(state),
  [CREATE_CONTRAT_SUCCESS]: (state, action) => {
    return flow(
      set('entities.contrats', {
        ...state.entities.contrats,
        ...action.response.entities.contrats
      }),
      set('loaded.contrats', true),
      set('loading.contrats', false)
    )(state);
  }
};

export function createContrat(data) {
  return {
    [CALL_API]: {
      types: [CREATE_CONTRAT_REQUEST, CREATE_CONTRAT_SUCCESS, CREATE_CONTRAT_FAILURE],
      method: 'POST',
      endpoint: '/contrats',
      schema: Schemas.CONTRAT,
      body: data
    }
  };
}

export function loadContrats(clientId) {
  return {
    clientId,
    [CALL_API]: {
      types: [FETCH_ALL_CONTRATS_REQUEST, FETCH_ALL_CONTRATS_SUCCESS, FETCH_ALL_CONTRATS_FAILURE],
      method: 'GET',
      endpoint: `/contrats/client/${clientId}`,
      schema: Schemas.CONTRAT_ARRAY,
      successMessage: 'Contrats chargées avec succès !'
    }
  };
}
