import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadContrats } from '../../store/modules/contrats';
import lifecycle from 'page-lifecycle';
import { BrowserView, MobileView } from 'react-device-detect';

import { filter, map, sumBy, orderBy } from 'lodash';

import moment from 'moment';
import fr from 'moment/locale/fr';
moment.locale('fr', fr);

import BaseLayout from '../shared/BaseLayout';

import TabsSelector from '../../components/shared/TabsSelector';
import CardContrat from '../../components/cards/CardContrat';
import SwitcherValue from '../../components/shared/SwitcherValue';

import { displayBonCommande } from '../../utils/methodes';

const Contrat = ({ user, cereale, contrats, apports, loadContrats }) => {
  const optionsAnnees = moment().isSameOrAfter(moment(`${moment().year()}-06-01`).startOf('day'))
    ? [
        {
          label: moment().year(),
          value: moment().year()
        },
        {
          label: moment().add(1, 'years').year(),
          value: moment().add(1, 'years').year()
        }
      ]
    : [
        {
          label: moment().subtract(1, 'years').year(),
          value: moment().subtract(1, 'years').year()
        },
        {
          label: moment().year(),
          value: moment().year()
        }
      ];

  const [activeAnneeCollecte, setActiveAnneeCollecte] = useState(optionsAnnees[0].value);

  useEffect(() => {
    function loadDatas() {
      loadContrats(user.client_prios_id);
    }

    const listener = lifecycle.addEventListener('statechange', function (evt) {
      if (
        (lifecycle.state === 'active' || lifecycle.state === 'passive') &&
        evt.oldState !== 'active'
      ) {
        loadDatas();
      }
    });

    loadDatas();

    return () => {
      lifecycle.removeEventListener('statechange', listener);
    };
  }, []);

  const [activeTab, setActiveTab] = useState('CONTRATS');

  const totalQuantityContrats = Number(
    sumBy(
      filter(contrats, (c) => c.annee_collecte === activeAnneeCollecte),
      (c) => c.quantite
    )
  ).toFixed(2);

  const totalQuantityApports = Number(
    sumBy(
      filter(apports, (c) => c.annee_collecte === activeAnneeCollecte),
      (c) => c.quantite
    )
  ).toFixed(3);
  const navigation = useHistory();

  const apportsData =
    apports &&
    map(
      filter(apports, (c) => c.annee_collecte === activeAnneeCollecte),
      (a) => ({
        ...a,
        numBonCommande: displayBonCommande(a.apport_id)
          ? Number(displayBonCommande(a.apport_id))
          : a.apport_id
      })
    );

  return (
    <BaseLayout isBack title="Mes contrats" icon="handshake-simple">
      <div className="container">
        <div className="page-header page-header-bar">
          <div className="page-header-prev btn" onClick={() => navigation.goBack()}>
            <i className="fa-solid fa-chevron-left" />
          </div>
          <div className="page-header-left">
            <div className="page-header-title">
              <div className="page-header-maintitle">{cereale && cereale.libelle}</div>
            </div>
          </div>
          <div className="page-header-right">
            <SwitcherValue
              activeOption={activeAnneeCollecte}
              onChangeValue={(v) => setActiveAnneeCollecte(v)}
              options={optionsAnnees}
            />
          </div>
        </div>
        <div className="page-content">
          <MobileView className="tabs-container">
            <TabsSelector
              activeTab={activeTab}
              onChangeTab={(value) => setActiveTab(value)}
              options={[
                {
                  label: 'Contrats',
                  value: 'CONTRATS'
                },
                {
                  label: 'Apports',
                  value: 'APPORTS'
                }
              ]}
            />
            <div className="tab-content">
              {activeTab && activeTab === 'CONTRATS' && (
                <Fragment>
                  <div className="liste">
                    {map(
                      orderBy(
                        filter(contrats, (c) => c.annee_collecte === activeAnneeCollecte),
                        ['date_engagement'],
                        ['desc']
                      ),
                      (c) => (
                        <CardContrat key={c.contrat_id} contrat={c} />
                      )
                    )}
                  </div>
                  <div className="tab-content-footer">
                    <span className="tab-content-footer-title">Total</span>
                    <span className="tab-content-footer-value">
                      {totalQuantityContrats || 0}&nbsp;t
                    </span>
                  </div>
                </Fragment>
              )}
              {activeTab && activeTab === 'APPORTS' && (
                <Fragment>
                  <div className="tab-content-header">
                    Sous réserve de validation des données par le service collecte sous 72H
                  </div>
                  <div className="liste">
                    {map(
                      orderBy(apportsData, ['date_apport', 'numBonCommande'], ['desc', 'desc']),
                      (a) => (
                        <div key={a.apport_id} className="contrat">
                          <div className="contrat-infos">
                            <span className="contrat-date">
                              {moment(a.date_apport).format('DD/MM/YYYY')}
                            </span>
                            <span className="contrat-tonnage">{a.quantite} t</span>
                          </div>
                          {displayBonCommande(a.apport_id) && (
                            <div className="contrat-infos">
                              <div className="contrat-data">
                                <span>Bon d'apport</span>
                                <span className="contrat-data-value">
                                  {displayBonCommande(a.apport_id)}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="contrat-infos">
                            <div className="contrat-data">
                              <span>Humidité</span>
                              <span className="contrat-data-value">{a.humidite} %</span>
                            </div>
                            <div className="contrat-data">
                              <span>PS</span>
                              <span className="contrat-data-value">{a.ps}</span>
                            </div>
                            <div className="contrat-data">
                              <span>Impureté</span>
                              <span className="contrat-data-value">{a.impurete} %</span>
                            </div>
                            <div className="contrat-data">
                              <span>Protéine</span>
                              <span className="contrat-data-value">{a.proteine} %</span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="tab-content-footer">
                    <span className="tab-content-footer-title">Total poids normé</span>
                    <span className="tab-content-footer-value">
                      {totalQuantityApports || 0}&nbsp;t
                    </span>
                  </div>
                </Fragment>
              )}
            </div>
          </MobileView>
          <BrowserView className="tabs-container">
            <div className="grid-spaceBetween flex-1">
              <div className="col-6_xs-12 pr-20">
                <div className="tab-content">
                  <div className="tab-cln-title">Contrats</div>
                  <div className="liste">
                    {map(
                      orderBy(
                        filter(contrats, (c) => c.annee_collecte === activeAnneeCollecte),
                        ['date_engagement'],
                        ['desc']
                      ),
                      (c) => (
                        <CardContrat key={c.contrat_id} contrat={c} />
                      )
                    )}
                  </div>
                  <div className="tab-content-footer">
                    <span className="tab-content-footer-title">Total</span>
                    <span className="tab-content-footer-value">
                      {totalQuantityContrats || 0}&nbsp;t
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-6_xs-12 pl-20">
                <div className="tab-content">
                  <div className="tab-cln-title">Apports</div>
                  <div className="tab-content-header">
                    Sous réserve de validation des données par le service collecte sous 72H
                  </div>
                  <div className="liste">
                    {map(
                      orderBy(apportsData, ['date_apport', 'numBonCommande'], ['desc', 'desc']),
                      (a) => (
                        <div key={a.apport_id} className="contrat">
                          <div className="contrat-infos">
                            <span className="contrat-date">
                              {moment(a.date_apport).format('DD/MM/YYYY')}
                            </span>
                            <span className="contrat-tonnage">{a.quantite} t</span>
                          </div>
                          {displayBonCommande(a.apport_id) && (
                            <div className="contrat-infos">
                              <div className="contrat-data">
                                <span>Bon d'apport</span>
                                <span className="contrat-data-value">
                                  {displayBonCommande(a.apport_id)}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="contrat-infos">
                            <div className="contrat-data">
                              <span>Humidité</span>
                              <span className="contrat-data-value">{a.humidite} %</span>
                            </div>
                            <div className="contrat-data">
                              <span>PS</span>
                              <span className="contrat-data-value">{a.ps}</span>
                            </div>
                            <div className="contrat-data">
                              <span>Impureté</span>
                              <span className="contrat-data-value">{a.impurete} %</span>
                            </div>
                            <div className="contrat-data">
                              <span>Protéine</span>
                              <span className="contrat-data-value">{a.proteine} %</span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="tab-content-footer">
                    <span className="tab-content-footer-title">Total poids normé</span>
                    <span className="tab-content-footer-value">
                      {totalQuantityApports || 0}&nbsp;t
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </BrowserView>
        </div>
      </div>
    </BaseLayout>
  );
};

Contrat.propTypes = {
  user: PropTypes.object.isRequired,
  loadContrats: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { cerealeId }
    }
  } = props;

  return {
    user: state.auth.utilisateur && state.auth.utilisateur,
    cereales: state.data.entities.cereales,
    cereale: state.data.entities.cereales[cerealeId],
    apports:
      state.data.entities.apports &&
      filter(state.data.entities.apports, (c) => c.cereale_prios_id === cerealeId),
    contrats:
      state.data.entities.contrats &&
      filter(state.data.entities.contrats, (c) => c.cereale_prios_id === cerealeId)
  };
};

export default connect(mapStateToProps, {
  loadContrats
})(Contrat);
