import React, { useState } from 'react';
import { connect } from 'react-redux';
import { recoverPassword } from '../../store/modules/auth';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import ModalSuccessResetPasswordSend from '../../components/modals/ModalSuccessResetPasswordSend';

const PasswordOubliePage = ({ user, recoverPassword }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const required = (value) => (value ? undefined : 'Champ requis !');

  const handleSubmit = (values) => {
    if (values.siret.length != 14 || isNaN(values.siret)) {
      setError('Le numéro de SIRET doit contenir 14 chiffres');
    } else {
      return recoverPassword(values.siret)
        .then(() => {
          setError(null);
          setSuccess(true);
        })
        .catch((err) => setError(err));
    }
  };

  return (
    <div className="authentication forgot-password">
      <div className="authentication-block">
        <div className="logo-block">
          <img src={require(`../../../assets/images/logo-terdici-collectes.svg`)} />
        </div>
        <div className="box">
          <div className="box-header">
            <div className="box-header-actions">
              <NavLink to="/" className="btn">
                <i className="far fa-chevron-left"></i>
              </NavLink>
            </div>
            <div className="box-header-title">Définir mon mot de passe</div>
            <div className="box-header-subtitle">
              Un email vous sera adressé afin de pouvoir définir votre mot de passe.
            </div>
          </div>
          <div className="box-content">
            {success && <ModalSuccessResetPasswordSend />}
            <Form
              onSubmit={handleSubmit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field name="siret" validate={required} component="input" className="field">
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" />
                        <label>SIRET</label>
                      </div>
                    )}
                  </Field>
                  {error && (
                    <div className="error-block ">
                      <span className="error-icon">
                        <i className="fas fa-exclamation" />
                      </span>
                      <div className="error-message">{error}</div>
                    </div>
                  )}
                  <div className="authentication-btn-group">
                    <button type="submit" className="btn btn-primary">
                      <span>Envoyer</span>
                    </button>
                    <NavLink className="btn-link" to="/connexion">
                      Revenir à la page de connexion
                    </NavLink>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
      <div className="authentication-overflow"></div>
    </div>
  );
};

PasswordOubliePage.propTypes = {
  user: PropTypes.object,
  recoverPassword: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, { recoverPassword })(PasswordOubliePage);
