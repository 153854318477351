import React from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';

const TabsSelector = ({ options, activeTab, onChangeTab }) => {
  return (
    <div className="tabs">
      {map(options, (o, i) => (
        <div
          onClick={() => onChangeTab(o.value)}
          key={i}
          className={`tab ${activeTab === o.value ? 'active' : ''}`}
        >
          {o.label}
        </div>
      ))}
    </div>
  );
};

TabsSelector.propTypes = {
  options: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired
};

export default TabsSelector;
