import React, { useState } from 'react';
import { connect } from 'react-redux';
import { clearVente, setCour, setVente } from '../../store/modules/ui';
import { loadCours } from '../../store/modules/cours';

import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { cloneDeep, filter, isString } from 'lodash';
import { UPLOADS_URL } from '../../constants/Config';
import ModalChangementPrix from './ModalChangementPrix';

const ModalVenteCereales = ({ setVente, setCour, clearVente, loadCours, vente, cour, user }) => {
  const [error, setError] = useState(null);
  const [courActuel, setCourActuel] = useState(null);
  const [changementPrix, setChangementPrix] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [CGV, setCGV] = useState((vente && vente.cgv) || false);

  const onChangeQuantite = (event) => {
    const stateVente = cloneDeep(vente);
    const quantite = event.target.value.replace('.', ',');
    stateVente.data.quantite = quantite;
    setVente(stateVente);
  };

  const onChangeLivraison = (type) => {
    const stateVente = cloneDeep(vente);

    stateVente.data.mode_livraison = type;
    setVente(stateVente);
  };

  const onChangeCGV = () => {
    const stateVente = cloneDeep(vente);

    stateVente.cgv = !stateVente.cgv;

    setCGV(stateVente.cgv);
    setVente(stateVente);
  };

  const handleSubmit = () => {
    setDisabled(false);
    setError(null);

    const stateVente = cloneDeep(vente);
    const stateCour = cloneDeep(cour);

    loadCours(user.client_prios_id).then((res) => {
      const cours = res.response.entities.cours;
      const cour = filter(
        cours,
        (c) =>
          c.cereale_prios_id === vente.cereale_id &&
          c.annee_collecte === vente.data.annee_collecte &&
          c.suspendu === false
      )[0];

      if (cour && cour.cours_actuel) {
        const courActuel = cour.cours_actuel;

        const prixActuelAvecMaj =
          courActuel + (user && user.price_zone_maj_min && user.price_zone_maj_min) || 0;

        setCourActuel(prixActuelAvecMaj);

        if (stateCour.cours_actuel !== courActuel) {
          setChangementPrix(true);
          stateVente.open_modal_vente = true;
          stateVente.data.cours_actuel = courActuel;
          setVente(stateVente);

          stateCour.cours_actuel = courActuel;
          setCour(stateCour);
        } else {
          setChangementPrix(false);

          const quantiteFormated = isString(stateVente.data.quantite)
            ? stateVente.data.quantite.replace(',', '.')
            : stateVente.data.quantite;
          stateVente.data.mode_livraison = 'DEPOT';
          stateVente.data.cours_actuel = courActuel;
          stateVente.data.quantite = Number(parseFloat(quantiteFormated).toFixed(3));
          stateVente.cgv = false;
          stateVente.open_modal_vente = false;
          stateVente.open_modal_confirm = true;

          setVente(stateVente);
        }
      }
    });
  };

  const closeModalChangePrix = () => {
    const stateVente = cloneDeep(vente);
    stateVente.data.prix = courActuel;
    setVente(stateVente);
    setChangementPrix(false);
  };
  const clearDataVente = () => {
    setError(null);
    clearVente();
  };
  return (
    <Modal
      className="modal-content small-modal"
      overlayClassName="modal-overlay"
      id={`modal-vente-${vente && vente.cereale_prios_id}`}
      isOpen={vente && vente.open_modal_vente}
    >
      <ModalChangementPrix
        isOpen={changementPrix}
        courActuel={courActuel}
        closeModalChangePrix={() => closeModalChangePrix()}
      />
      <div className="modal-title">Vente de céréales</div>
      <div className="modal-body">
        <div className="block-highlight-secondary">
          <div>
            <div className="cereal-title">{vente && vente.nom_cereale}</div>
            <div>{vente && vente.data && vente.data.prix} €/Tonne</div>
          </div>
          <div>
            <div className="annee-vente">{vente && vente.data && vente.data.annee_collecte}</div>
          </div>
        </div>
        <div className="field">
          <input
            id="quantite"
            type="decimal"
            inputMode="decimal"
            lang="fr"
            step="any"
            min="0"
            maxLength={5}
            value={vente && vente.data.quantite}
            onChange={onChangeQuantite}
          />
          <label htmlFor="quantite" required>
            Quantité en tonnes *
          </label>
          <p>
            Dans le cas où vous souhaiteriez faire appel à notre service logistique, merci de
            réaliser vos engagements par tranche de 25 tonnes (Hors Colza)
          </p>

          <p>Des frais de transport, stockage, séchage peuvent s’appliquer.</p>
        </div>
        {/*     <div className="field">
          <label>Mode de livraison *</label>
          <div className="block-radio-icon">
            <button
              type="button"
              disabled={vente && vente.data && vente.data.mode_livraison === 'DEPOT'}
              className="radio-icon"
              onClick={() => {
                onChangeLivraison('DEPOT');
              }}
            >
              <i className="fas fa-farm" />
              <span>Arrivée usine ou dépôt</span>
            </button>
            <button
              type="button"
              disabled={vente && vente.data && vente.data.mode_livraison === 'CHAMP'}
              className="radio-icon"
              onClick={() => {
                onChangeLivraison('CHAMP');
              }}
            >
              <i className="tf-field" />
              <span>Départ champ</span>
            </button>
          </div>
        </div> */}
        <div className="field">
          <input
            type="checkbox"
            required
            name="CGV"
            id="CGV"
            value={vente.cgv}
            onChange={() => onChangeCGV()}
          />
          <label htmlFor="CGV">
            J'ai bien pris connaissance des{' '}
            <a className="link" href={`${UPLOADS_URL}terdici_cga.pdf`} target="_blank">
              Conditions Générales d'Achat
            </a>
          </label>
          {error && (
            <div className="error-block ">
              <span className="error-icon">
                <i className="fas fa-exclamation" />
              </span>
              <div className="error-message">{error}</div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <div className="btn-group">
            <button onClick={() => clearDataVente()} className="btn btn-tertiary">
              <span>Annuler</span>
            </button>
            <button
              className="btn btn-secondary"
              disabled={disabled}
              onClick={() => {
                if (vente && vente.data && vente.data.quantite === '') {
                  return setError('Vous devez saisir une quantité');
                }
                if (vente && vente.data && vente.data.quantite <= 0) {
                  return setError('Vous devez saisir une quantité supérieure à 0');
                }
                if (CGV === false) {
                  return setError('Vous devez valider les CGA');
                } else {
                  event.preventDefault();
                  return handleSubmit();
                }
              }}
            >
              <span>Je vends </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalVenteCereales.propTypes = {
  cours: PropTypes.object,
  loadCours: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    user: state.auth.utilisateur,
    vente: state.data.entities.ui.vente,
    cour: state.data.entities.ui.cour
  }),
  { setVente, setCour, clearVente, loadCours }
)(ModalVenteCereales);
