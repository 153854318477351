import initialState from '../initialState';
import '../../index';
import { clientsActionsHandlers } from './clients';
import { cerealesActionsHandlers } from './cereales';
import { contactsActionsHandlers } from './contacts';
import { coursActionsHandlers } from './cours';
import { contratsActionsHandlers } from './contrats';
import { commerciauxActionsHandlers } from './commerciaux';
import { apportsActionsHandlers } from './apports';
import { uiActionsHandlers } from './ui';

const actionsHandlers = {
  ...uiActionsHandlers,
  ...clientsActionsHandlers,
  ...contactsActionsHandlers,
  ...coursActionsHandlers,
  ...cerealesActionsHandlers,
  ...contratsActionsHandlers,
  ...commerciauxActionsHandlers,
  ...apportsActionsHandlers
};

export default function reducer(state = initialState, action) {
  const reduceFn = actionsHandlers[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
