export default {
  entities: {
    ui: {},
    cereales: {},
    cours: {},
    clients: {},
    utilisateurs: {},
    contacts: {},
    contrats: {},
    commerciaux: {},
    apports: {}
  },
  loaded: {
    ui: false,
    appstorage: false,
    cours: false,
    cereales: false,
    clients: false,
    utilisateurs: false,
    contacts: false,
    contrats: false,
    commerciaux: false,
    apports: false
  },
  loading: {
    ui: false,
    cours: false,
    cereales: false,
    clients: false,
    utilisateurs: false,
    contacts: false,
    contrats: false,
    commerciaux: false,
    apports: false
  }
};
