import { isEmpty } from 'lodash';

export function renderTelephonesClient(client) {
  const telephones = [];

  if (client && client.telephone_1) {
    telephones.push(client.telephone_1);
  }
  if (client && client.telephone_2) {
    telephones.push(client.telephone_2);
  }
  if (client && client.telephone_3) {
    telephones.push(client.telephone_3);
  }
  return client && !isEmpty(telephones) ? telephones.join(' / ') : '';
}

export function getPourcentCotation(newCotation, oldCotation) {
  return (newCotation * 100) / oldCotation;
}
export function getTendanceClass(newCotation, oldCotation) {
  if (newCotation && oldCotation) {
    if (newCotation == oldCotation) {
      return 'equal';
    }
    if (newCotation > oldCotation) {
      return 'up';
    } else {
      return 'down';
    }
  } else {
    return 'not-cotation';
  }
}

export function displayBonCommande(apportId) {
  const data = String(apportId).split('_');

  if (data && data.length === 2) {
    const num = String(apportId).split(/[-_]/)[1];
    return Number(num);
  } else {
    return '';
  }
}
