import React from 'react';
import PropTypes from 'prop-types';

import { API_URL } from '../constants/Config';

const ContactCommercial = ({ version, commercial }) => {
  return (
    <div className={`card-contact commercial${version ? ' card-contact-' + version : ''}`}>
      <div className="card-main_infos">
        <div className="card-avatar">
          {commercial && commercial.photo && <img src={`${API_URL}/uploads/${commercial.photo}`} />}
        </div>
        <div className="card-infos">
          <div className="card-data">
            <div className="card-data-label">Votre contact commercial</div>
            <div className="card-data-value">
              {(commercial && commercial.prenom) || ''} {(commercial && commercial.nom) || ''}
            </div>
          </div>

          {commercial && commercial.telephone && (
            <a href={`tel:${commercial.telephone}`} target="_blank" className="card-data">
              <i className="fas fa-phone" />
              <div className="card-data-value">{commercial.telephone}</div>
            </a>
          )}

          {commercial && commercial.email && (
            <a href={`mailto:${commercial.email}`} target="_blank" className="card-data">
              <i className="fas fa-envelope" />
              <div className="card-data-value">{commercial.email}</div>
            </a>
          )}
        </div>
      </div>
      <div className="card-actions">
        <a href={`tel:${commercial.telephone}`} target="_blank">
          <i className="fas fa-phone"></i>
        </a>
      </div>
    </div>
  );
};
ContactCommercial.propTypes = {
  version: PropTypes.string,
  commercial: PropTypes.object.isRequired
};

export default ContactCommercial;
