import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { orderBy, map, filter, includes, groupBy, values } from 'lodash';
import moment from 'moment';
import fr from 'moment/locale/fr';
moment.locale('fr', fr);

import lifecycle from 'page-lifecycle';

import { checkClientBlocked, loadUserClient, logout } from '../store/modules/auth';
import { purge } from '../store/modules/settings';

import { updateDateConnexionClient } from '../store/modules/clients';
import { loadCereales } from '../store/modules/cereales';
import { loadCours } from '../store/modules/cours';
import { loadContrats } from '../store/modules/contrats';
import { getCommercialPrincipal } from '../store/modules/commerciaux';
import { clearVente } from '../store/modules/ui';

import BaseLayout from './shared/BaseLayout';
import ContactCommercial from '../components/ContactCommercial';
import CerealeCard from '../components/cards/CerealeCard';
import ModalSelectCereales from '../components/modals/ModalSelectCereales';
import SwitcherValue from '../components/shared/SwitcherValue';

import ModalVenteCereales from '../components/modals/ModalVenteCereales';
import ModalSuccessVenteCereales from '../components/modals/ModalSuccessVenteCereales';
import ModalConfirmVenteCereales from '../components/modals/ModalConfirmVenteCereales';

const Home = ({
  logout,
  checkClientBlocked,
  purge,
  updateDateConnexionClient,
  loadCereales,
  loadCours,
  loadContrats,
  getCommercialPrincipal,
  client,
  vente,
  cour,
  cours,
  listCereales,
  watchListCereales,
  commercial,
  clearVente,
  loadUserClient
}) => {
  const optionsAnnees = moment().isSameOrAfter(moment(`${moment().year()}-06-01`).startOf('day'))
    ? [
        {
          label: moment().year(),
          value: moment().year()
        },
        {
          label: moment().add(1, 'years').year(),
          value: moment().add(1, 'years').year()
        }
      ]
    : [
        {
          label: moment().subtract(1, 'years').year(),
          value: moment().subtract(1, 'years').year()
        },
        {
          label: moment().year(),
          value: moment().year()
        }
      ];

  const [activeAnneeCollecte, setActiveAnneeCollecte] = useState(optionsAnnees[0].value);

  const [isOpen, setIsOpen] = useState(false);

  const [watchListCerealesData, setWatchListCerealesData] = useState([]);

  const navigation = useHistory();

  useEffect(() => {
    const idsCereales = map(watchListCereales, (c) => c.cereale_prios_id);

    setWatchListCerealesData(
      watchListCereales &&
        cours &&
        groupBy(
          map(
            filter(cours, (c) => includes(idsCereales, c.cereale_prios_id)),
            (o) => {
              return {
                cereale: listCereales[o.cereale_prios_id],
                ...o
              };
            }
          ),
          'annee_collecte'
        )
    );
  }, [watchListCereales]);

  useEffect(() => {
    function loadDatas() {
      loadUserClient(client.client_prios_id);
      checkClientBlocked(client.client_prios_id).catch((err) => {
        return Promise.resolve()
          .then(() => logout())
          .then(() => purge())
          .then(() => navigation.push('/connexion'));
      });

      updateDateConnexionClient(client.client_prios_id);
      loadCereales();
      loadContrats(client.client_prios_id);
      loadCours(client.client_prios_id);
      getCommercialPrincipal(client.client_prios_id);
    }
    const listener = lifecycle.addEventListener('statechange', function (evt) {
      if (
        (lifecycle.state === 'active' || lifecycle.state === 'passive') &&
        evt.oldState !== 'active'
      ) {
        loadDatas();
      }
    });

    clearVente();

    loadDatas();
    return () => {
      lifecycle.removeEventListener('statechange', listener);
    };
  }, []);

  return (
    <BaseLayout title="Accueil" icon="home" slug="accueil">
      {isOpen && (
        <ModalSelectCereales
          listCereales={listCereales}
          cerealesWatchList={client && client.cereales}
          openModal={isOpen}
          closeModal={() => setIsOpen(false)}
        />
      )}

      <div className="container">
        <div className="page-header">
          <div className="page-header-left">
            <div className="page-header-title">
              <div className="page-header-maintitle">
                Cotation de mes céréales (Suspension à 17H30)
              </div>
            </div>
          </div>

          <div className="page-header-right">
            <SwitcherValue
              activeOption={activeAnneeCollecte}
              onChangeValue={(v) => setActiveAnneeCollecte(v)}
              options={optionsAnnees}
            />
          </div>
        </div>
        {vente &&
          cour &&
          vente.cereale_id === cour.cereale.cereale_prios_id &&
          vente.open_modal_vente && <ModalVenteCereales />}

        {vente && cour && vente.cereale_id === cour.cereale.cereale_prios_id && (
          <ModalConfirmVenteCereales />
        )}
        {vente &&
          cour &&
          vente.cereale_id === cour.cereale.cereale_prios_id &&
          vente.open_modal_success && <ModalSuccessVenteCereales />}
        {watchListCerealesData && watchListCerealesData[activeAnneeCollecte] ? (
          <div className="page-content">
            <div className="block-cards-cereales">
              {map(
                orderBy(watchListCerealesData[activeAnneeCollecte], ['cereale.libelle'], ['asc']),
                (cour) => (
                  <CerealeCard key={cour.cours_id} cour={cour} />
                )
              )}
            </div>
            <div className="btn-group center">
              <button className="btn-big-icon" onClick={() => setIsOpen(true)}>
                <div className="icon">
                  <i className="fas fa-wheat"></i>
                </div>
                <span>Ajouter des céréales</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="content-centered">
            <div className="message">Vous n'avez pas encore ajouté vos céréales</div>{' '}
            <button className="btn-big-icon" onClick={() => setIsOpen(true)}>
              <div className="icon">
                <i className="fas fa-wheat"></i>
              </div>
              <span>Ajouter des céréales</span>
            </button>
          </div>
        )}
        {commercial && <ContactCommercial version="short" commercial={commercial} />}
      </div>
    </BaseLayout>
  );
};

Home.propTypes = {
  client: PropTypes.object.isRequired,
  listCereales: PropTypes.object,
  commerciaux: PropTypes.object,
  updateDateConnexionClient: PropTypes.func.isRequired,
  loadUserClient: PropTypes.func.isRequired,
  loadCereales: PropTypes.func.isRequired,
  loadCours: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  purge: PropTypes.func.isRequired,
  getCommercialPrincipal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const listCereales = state.data.entities.cereales;

  const watchListCereales =
    state.auth &&
    state.auth.utilisateur.cereales &&
    filter(listCereales, (c) => includes(state.auth.utilisateur.cereales, c.cereale_prios_id));

  return {
    cours: state.data.entities.cours,
    client: state.auth.utilisateur,
    commercial: state.data.entities.commerciaux && values(state.data.entities.commerciaux)[0],
    watchListCereales: watchListCereales || [],
    listCereales: listCereales,

    vente: state.data.entities.ui.vente,
    cour: state.data.entities.ui.cour
  };
};

export default connect(mapStateToProps, {
  logout,
  loadUserClient,
  purge,
  checkClientBlocked,
  updateDateConnexionClient,
  loadContrats,
  loadCereales,
  loadCours,
  getCommercialPrincipal,
  clearVente
})(Home);
