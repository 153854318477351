import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

export function requireAuthentication(Component) {
  const AuthenticatedComponent = (props) => {
    useEffect(() => {
      checkAuth();
    }, []);

    useEffect(() => {
      checkAuth();
    }, [props.location]);

    const checkAuth = () => {
      if (!props.utilisateur || props.utilisateur.isBlocked) {
        const redirectAfterLogin = props.location.pathname;
        props.history.push(`/connexion?next=${redirectAfterLogin}`);
      }
    };

    return <div>{props.utilisateur ? <Component {...props} /> : null}</div>;
  };

  const mapStateToProps = (state) => ({
    utilisateur: state.auth.utilisateur
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
}
