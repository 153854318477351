import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { recoverPassword, logout, loadUserClient } from '../store/modules/auth';

import { NavLink } from 'react-router-dom';

import { purge } from '../store/modules/settings';

import BaseLayout from './shared/BaseLayout';
import ModalSuccessResetPasswordSendCompte from '../components/modals/ModalSuccessResetPasswordSendCompte';
import { UPLOADS_URL } from '../constants/Config';
import { renderTelephonesClient } from '../utils/methodes';

const MonCompte = ({ client, recoverPassword, logout, purge, loadUserClient }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    loadUserClient(client.client_prios_id);
  }, []);

  const handleSubmit = (siret) => {
    setDisableButton(true);
    return recoverPassword(siret)
      .then(() => {
        setError(null);
        setSuccess(true);
        setDisableButton(false);
      })
      .catch((err) => setError(err));
  };

  const deconnexion = () => {
    return Promise.resolve()
      .then(() => logout())
      .then(() => purge());
  };

  return (
    <BaseLayout title="Mon compte" icon="user-cog">
      {success && <ModalSuccessResetPasswordSendCompte />}
      <Fragment>
        <div className="container">
          <div>
            <h2>Mes informations</h2>
            <div className="page-content">
              <div className="grid">
                <div className="col-8_sm-12">
                  <div className="grid-2_sm-1">
                    <div className="col">
                      <div className="data-label">Numéro de SIRET</div>
                      <div className="data-value">{client.siret}</div>
                    </div>
                    <div className="col">
                      <div className="data-label">Numéro de client</div>
                      <div className="data-value">{client.code}</div>
                    </div>
                    <div className="col">
                      <div className="data-label">Nom de la société</div>
                      <div className="data-value">
                        {client.raison_sociale_1} {client.raison_sociale_2}
                      </div>
                    </div>
                    <div className="col">
                      <div className="data-label">Adresse</div>
                      <div className="data-value">
                        {client.adresse_2 || ''} {client.code_postal} {client.ville}
                      </div>
                    </div>
                    <div className="col">
                      <div className="data-label">Téléphone(s)</div>
                      <div className="data-value">{renderTelephonesClient(client)}</div>
                    </div>
                    <div className="col">
                      <div className="data-label">Adresse mail</div>
                      <div className="data-value">{client.email}</div>
                    </div>
                  </div>
                  <div className="block-highlight">
                    <div className="flexbox justifyCenter flexWrap text-center mt-10 mb-14">
                      <span className="mx-2">Des informations sont erronées ?</span>
                      <span className="mx-2">Merci de contacter votre service collecte :</span>
                    </div>
                    <div className="grid-2_xs-1-center">
                      <div className="col flexbox center">
                        <a href="tel:02 99 49 51 36">
                          <i className="fas fa-phone-alt" />
                          <span>02 99 49 51 36</span>
                        </a>
                      </div>
                      <div className="col flexbox center">
                        <a href="mailto:collectes@terdici-agri.fr">
                          <i className="fas fa-envelope"></i>
                          <span>collectes@terdici-agri.fr</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2>Modifier mon mot de passe</h2>
            <div className="grid">
              <div className="col-8_sm-12">
                <div className="page-content center">
                  <p>Un email vous sera adressé afin de pouvoir définir votre mot de passe</p>
                  <div className="btn-group center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={disableButton}
                      onClick={() => handleSubmit(client.siret)}
                    >
                      Envoyer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2>Informations</h2>
            <ul className="list-link">
              <li>
                <a
                  className="link"
                  href={`${UPLOADS_URL}terdici_mentions_legales_cgu.pdf`}
                  target="_blank"
                >
                  Mentions légales et CGU
                </a>
              </li>
              <li>
                <a className="link" href={`${UPLOADS_URL}terdici_cga.pdf`} target="_blank">
                  Conditions Générales d'Achat
                </a>
              </li>
            </ul>
          </div>
          <div>
            <NavLink className="btn" to="/connexion" onClick={() => deconnexion()}>
              <button type="button" className="btn btn-primary">
                Déconnexion
              </button>
            </NavLink>
          </div>
        </div>
      </Fragment>
    </BaseLayout>
  );
};

MonCompte.propTypes = {
  client: PropTypes.object.isRequired,
  recoverPassword: PropTypes.func.isRequired,
  loadUserClient: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  purge: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    client: state.auth.utilisateur
  }),
  { recoverPassword, logout, purge, loadUserClient }
)(MonCompte);
