import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

const ModalSuccessCreatePassword = () => {
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();

  const confirm = () => {
    setIsOpen(false);
    history.replace('/');
  };
  return (
    <Modal
      className="modal-content modal-classic small-modal"
      overlayClassName="modal-overlay"
      id="confirm-delete"
      isOpen={isOpen}
    >
      <div className="modal-title">
        Mot de passe défini
        <div
          onClick={() => {
            setIsOpen(false);
            history.replace('/');
          }}
          className="btn-close"
        >
          <i className="fas fa-times" />
        </div>
      </div>
      <div className="modal-body">Votre mot de passe a bien été défini</div>

      <div className="modal-footer">
        <div className="btn-group">
          <button className="btn btn-secondary" onClick={() => confirm()}>
            <span>Ok, je me connecte à mon compte</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSuccessCreatePassword;
