import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loadCours } from '../../store/modules/cours';
import { clearVente, setVente } from '../../store/modules/ui';

import { createContrat } from '../../store/modules/contrats';

import { cloneDeep } from 'lodash';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalConfirmVenteCereales = ({ createContrat, setVente, vente, clearVente }) => {
  const [error, setError] = useState(null);

  const handleSubmit = () => {
    const stateVente = cloneDeep(vente);
    return createContrat(vente.data)
      .then(() => {
        stateVente.open_modal_confirm = false;
        stateVente.open_modal_success = true;
        setVente(stateVente);
      })
      .catch((err) => {
        setError(err);
      });
  };

  const backModification = () => {
    setError(null);
    const stateVente = cloneDeep(vente);

    stateVente.open_modal_confirm = false;
    stateVente.open_modal_vente = true;
    setVente(stateVente);
  };
  return (
    <Fragment>
      <Modal {...modalDefaultClass} id="confirm-vente" isOpen={vente && vente.open_modal_confirm}>
        <div className="modal-title">
          Confirmation avant vente
          <div
            onClick={() => {
              clearVente();
              setError(null);
            }}
            className="btn-close"
          >
            <i className="fas fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <div className="block-highlight-secondary">
            <div>
              <div className="cereal-title">{vente && vente.nom_cereale}</div>
              <div>
                {vente && vente.data && vente.data.prix}
                €/Tonne
              </div>
            </div>
            <div>
              <div className="annee-vente">{vente && vente.data && vente.data.annee_collecte}</div>
            </div>
          </div>
          <div className="grid-center">
            <div>
              <div className="vente-data">
                <span className="vente-data-icon">
                  <i className="fa-solid fa-weight-hanging" />
                </span>
                <span className="vente-data-value">
                  {vente && vente.data && vente.data.quantite} t
                </span>
              </div>
              {/*    <div className="vente-data mt-14">
                <span
                  className={`vente-data-icon ${
                    vente && vente.data && vente.data.mode_livraison == 'DEPOT' ? 'depot' : 'depart'
                  }`}
                >
                  {vente && vente.data && vente.data.mode_livraison == 'DEPOT' ? (
                    <i className="fas fa-farm" />
                  ) : (
                    <i className="tf-field" />
                  )}
                </span>
                <span className="vente-data-value">
                  {vente && vente.data && vente.data.mode_livraison == 'DEPOT'
                    ? 'Arrivée usine ou dépôt'
                    : 'Départ champ'}
                </span>
              </div> */}
            </div>
          </div>
          {error && (
            <div className="error-block ">
              <span className="error-icon">
                <i className="fas fa-exclamation" />
              </span>
              <div className="error-message">{error}</div>
            </div>
          )}
          {/* Mettre un spinner et désactiver les boutons en cas de validation */}
        </div>
        <div className="modal-footer">
          <div className="btn-group">
            <button onClick={() => backModification()} className="btn btn-tertiary">
              <span>Modifier</span>
            </button>
            <button className="btn btn-secondary" onClick={() => handleSubmit()}>
              <span>Je confirme</span>
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

ModalConfirmVenteCereales.propTypes = {
  createContrat: PropTypes.func.isRequired,
  vente: PropTypes.object.isRequired
};

export default connect(
  (state) => ({
    user: state.auth.utilisateur,
    cours: state.data.entities && state.data.entities.cours,
    vente: state.data.entities && state.data.entities.ui.vente
  }),
  { createContrat, clearVente, setVente, loadCours }
)(ModalConfirmVenteCereales);
