import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { login } from '../../store/modules/auth';
import { setConfig } from '../../store/modules/globals';

import { version } from '../../../package.json';

const LoginPage = ({ login, setConfig }) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const required = (value) => (value ? undefined : 'Champ requis !');

  const handleSubmit = (values) => {
    return login(values.siret, values.password)
      .then((res) => {
        return history.replace(`/`);
      })
      .then(() => setConfig('isLoading', false))
      .catch((err) => setError(err));
  };

  return (
    <Fragment>
      <div className="authentication">
        <div className="authentication-block">
          <div className="logo-block">
            <img src={require(`../../../assets/images/logo-terdici-collectes.svg`)} />
          </div>
          <div className="box">
            <div className="box-content">
              <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      name="siret"
                      // validate={requiredEmail}
                      component="input"
                      className="field"
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="number" inputMode="numeric" />
                          <label>SIRET *</label>
                        </div>
                      )}
                    </Field>

                    <Field validate={required} name="password" component="input" className="field">
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input
                            {...input}
                            type="password"
                            onChange={(value) => {
                              setError(null);
                              input.onChange(value);
                            }}
                          />
                          <label>Mot de passe *</label>
                        </div>
                      )}
                    </Field>
                    <div className="authentication-btn-group">
                      {error && (
                        <div className="error-block ">
                          <span className="error-icon">
                            <i className="fas fa-exclamation" />
                          </span>
                          <div className="error-message">{error}</div>
                        </div>
                      )}
                      <button type="submit" className="btn btn-primary">
                        <span>Je m'identifie</span>
                      </button>
                      <NavLink className="btn-link text-center" to="/mot-de-passe-oublie">
                        Première connexion
                        <br />
                        ou
                        <br />
                        mot de passe oublié
                      </NavLink>
                      <div className="version">v.{version}</div>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
        <div className="authentication-overflow"></div>
      </div>
    </Fragment>
  );
};

export default connect(null, { login, setConfig })(LoginPage);
