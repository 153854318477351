import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import MainNav from '../../components/shared/MainNav';
import HeaderBar from '../../components/shared/HeaderBar';

Modal.setAppElement('body');

const BaseLayout = ({ children, icon, isBack, title, slug }) => {
  return (
    <Fragment>
      {process.env.NODE_ENV === 'staging' && (
        <div id="staging-banner">
          <i className="fas fa-exclamation-triangle" />
          Environnement de recette
          <i className="fas fa-exclamation-triangle" />
        </div>
      )}
      <div id="wrapper" className={`${slug ? slug : ''}`}>
        <MainNav />
        <main>
          <HeaderBar isBack={isBack} icon={icon} title={title} />
          <section className="content">{children}</section>
        </main>
      </div>
    </Fragment>
  );
};
BaseLayout.propTypes = {
  children: PropTypes.node,
  slug: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string
};

export default connect((state) => ({
  account: state.auth.utilisateur
}))(BaseLayout);
