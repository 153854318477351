import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadContacts } from '../store/modules/contacts';

import lifecycle from 'page-lifecycle';

import { map, sortBy, values } from 'lodash';
import BaseLayout from './shared/BaseLayout';

import ContactCommercial from '../components/ContactCommercial';
import ContactCard from '../components/cards/ContactCard';

const Contacts = ({ client, contacts, loadContacts, commercial }) => {
  useEffect(() => {
    function loadDatas() {
      loadContacts(client.societe_prios_id);
    }

    const listener = lifecycle.addEventListener('statechange', function (evt) {
      if (
        (lifecycle.state === 'active' || lifecycle.state === 'passive') &&
        evt.oldState !== 'active'
      ) {
        loadDatas();
      }
    });

    loadDatas();

    return () => {
      lifecycle.removeEventListener('statechange', listener);
    };
  }, []);

  return (
    <BaseLayout title="Mes contacts" icon="address-book">
      <div className="container">
        <div className="page-content">
          {commercial && <ContactCommercial commercial={commercial} />}

          <div className="grid-3_sm-1_xl-2">
            {client &&
              client.societe_prios_id &&
              map(
                sortBy(contacts, [
                  function (c) {
                    return c.titre;
                  }
                ]),
                (c) => (
                  <div className="col" key={c._id}>
                    <ContactCard contact={c} />
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

Contacts.propTypes = {
  client: PropTypes.object.isRequired,
  contacts: PropTypes.object.isRequired
};

export default connect(
  (state) => ({
    commercial: state.data.entities.commerciaux && values(state.data.entities.commerciaux)[0],
    client: state.auth.utilisateur,
    contacts: state.data.entities.contacts
  }),
  { loadContacts }
)(Contacts);
