import { flow, set } from 'lodash/fp';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_COMMERCIAUX_REQUEST = 'tdc/FETCH_ALL_COMMERCIAUX_REQUEST';
const FETCH_ALL_COMMERCIAUX_SUCCESS = 'tdc/FETCH_ALL_COMMERCIAUX_SUCCESS';
const FETCH_ALL_COMMERCIAUX_FAILURE = 'tdc/FETCH_ALL_COMMERCIAUX_FAILURE';

export const commerciauxActionsHandlers = {
  [FETCH_ALL_COMMERCIAUX_REQUEST]: (state) =>
    flow(set('loaded.commerciaux', false), set('loading.commerciaux', true))(state),
  [FETCH_ALL_COMMERCIAUX_SUCCESS]: (state, action) => {
    return flow(
      set('entities.commerciaux', action.response.entities.commerciaux || {}),
      set('loaded.commerciaux', true),
      set('loading.commerciaux', false)
    )(state);
  },
  [FETCH_ALL_COMMERCIAUX_FAILURE]: (state) =>
    flow(set('loaded.commerciaux', false), set('loading.commerciaux', false))(state)
};

export function getCommercialPrincipal(client_id) {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_COMMERCIAUX_REQUEST,
        FETCH_ALL_COMMERCIAUX_SUCCESS,
        FETCH_ALL_COMMERCIAUX_FAILURE
      ],
      method: 'GET',
      endpoint: `/commerciaux/${client_id}`,
      schema: Schemas.COMMERCIAL_ARRAY,
      successMessage: 'Commerciaux du client chargés avec succès !'
    }
  };
}
