import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { clearVente } from '../../store/modules/ui';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

import animationGif from '../../../assets/images/animation-chargement.gif';

const ModalSuccessVenteCereales = ({ clearVente, vente }) => {
  const confirm = () => {
    return Promise.resolve()
      .then(() => {
        return setLoaded('');
      })
      .then(() => {
        return clearVente();
      });
  };

  const [loaded, setLoaded] = useState(animationGif);

  useEffect(() => {
    setLoaded('');
    setTimeout(() => {
      setLoaded(animationGif);
    }, 10);
  }, []);

  return (
    <Modal
      {...modalDefaultClass}
      id="confirm-vente_cereale"
      isOpen={vente && vente.open_modal_success}
    >
      <div className="modal-title">Vente enregistrée avec succès</div>
      <div className="modal-body">
        <p className="text-center">
          Merci pour la confirmation.
          <br />
          Vous allez recevoir le contrat sur votre adresse email sous 1 heure.
        </p>
        <img className="animationGif" src={loaded} />
      </div>

      <div className="modal-footer">
        <div className="btn-group">
          <button className="btn btn-secondary" onClick={() => confirm()}>
            <span>Ok</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  (state) => ({
    vente: state.data.entities && state.data.entities.ui.vente
  }),
  { clearVente }
)(ModalSuccessVenteCereales);
