import React from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { logout } from '../../store/modules/auth';
import { purge } from '../../store/modules/settings';

const HeaderBar = ({ icon, title, isBack, utilisateur, logout, purge }) => {
  const deconnexion = () => {
    return Promise.resolve()
      .then(() => logout())
      .then(() => purge());
  };
  const navigation = useHistory();

  return (
    <header className="header">
      <div className="container">
        {isBack && (
          <div className="header-left">
            <div className="btn" onClick={() => navigation.goBack()}>
              <i className="fa-solid fa-chevron-left" />
            </div>
          </div>
        )}
        <div className="header-infos">
          {icon && (
            <div className="header-infos-logo">
              <i className={`fas fa-${icon ? icon : ''}`}></i>
            </div>
          )}

          {title && <div className="header-infos-title">{title}</div>}
        </div>
        <div className="user-block">
          <div className="user-avatar"></div>
          <div className="user-profil">
            <span className="user-name">
              <span className="user-welcome">Bienvenue </span>
              {utilisateur && utilisateur.raison_sociale_1}{' '}
              {utilisateur && utilisateur.raison_sociale_2}
            </span>
            <span>{utilisateur && utilisateur.code}</span>
          </div>
          <NavLink className="btn" to="/connexion" onClick={() => deconnexion()}>
            <i className="far fa-sign-out" />
          </NavLink>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  utilisateur: state.auth.utilisateur
});

export default connect(mapStateToProps, { logout, purge })(HeaderBar);
