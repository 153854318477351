import { schema } from 'normalizr';

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/gaearon/normalizr

const loginSchema = new schema.Entity('client', {}, { idAttribute: 'client_prios_id' });
const clientSchema = new schema.Entity('clients', {}, { idAttribute: 'client_prios_id' });
const cerealeSchema = new schema.Entity('cereales', {}, { idAttribute: 'cereale_prios_id' });
const contactSchema = new schema.Entity('contacts', {}, { idAttribute: '_id' });
const courSchema = new schema.Entity('cours', {}, { idAttribute: 'cours_id' });
const contratSchema = new schema.Entity('contrats', {}, { idAttribute: 'contrat_id' });
const commercialSchema = new schema.Entity('commerciaux', {}, { idAttribute: 'utilisateur_id' });
const apportSchema = new schema.Entity('apports', {}, { idAttribute: 'apport_id' });

// Schemas for API responses.
export const Schemas = {
  LOGIN: loginSchema,
  CLIENT: clientSchema,
  CLIENT_ARRAY: new schema.Array(clientSchema),
  CEREALE: cerealeSchema,
  CEREALE_ARRAY: new schema.Array(cerealeSchema),
  CONTACT: contactSchema,
  CONTACT_ARRAY: new schema.Array(contactSchema),
  COURS: courSchema,
  COURS_ARRAY: new schema.Array(courSchema),
  CONTRAT: contratSchema,
  CONTRAT_ARRAY: new schema.Array(contratSchema),
  COMMERCIAL: commercialSchema,
  COMMERCIAL_ARRAY: new schema.Array(commercialSchema),
  APPORT: apportSchema,
  APPORT_ARRAY: new schema.Array(apportSchema)
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
