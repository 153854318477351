import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { updateUserCereales } from '../../store/modules/auth';
import { loadCours } from '../../store/modules/cours';

import { map, includes, xor, sortBy } from 'lodash';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';
import CerealeSelectCard from '../cards/CerealeSelectCard';

const ModalSelectCereales = ({
  user,
  loadCours,
  listCereales,
  cerealesWatchList,
  openModal,
  closeModal,
  updateUserCereales
}) => {
  const [watchList, setWatchList] = useState(cerealesWatchList);
  const [error, setError] = useState(null);

  const onChangeCereale = (cerealeId) => {
    setWatchList(xor(watchList, [cerealeId]));
  };

  const handleSubmit = () => {
    return updateUserCereales(user, { cereales: watchList })
      .then(() => {
        loadCours(user.client_prios_id);
        closeModal();
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <Modal {...modalDefaultClass} id="confirm-delete" isOpen={openModal}>
      <div className="modal-title">
        Sélectionnez ici les céréales dont vous voulez voir les cotations
        <div
          onClick={() => {
            closeModal();
            setWatchList(cerealesWatchList);
            setError(null);
          }}
          className="btn-close"
        >
          <i className="fas fa-times" />
        </div>
      </div>
      <div className="modal-body">
        {listCereales &&
          map(
            sortBy(listCereales, [
              function (c) {
                return c.libelle;
              }
            ]),
            (c) => (
              <CerealeSelectCard
                key={c.cereale_prios_id}
                cereale={c}
                isSelected={includes(watchList, c.cereale_prios_id)}
                onChangeCereale={() => onChangeCereale(c.cereale_prios_id)}
              />
            )
          )}
        {error && (
          <div className="error-block ">
            <span className="error-icon">
              <i className="fas fa-exclamation" />
            </span>
            <div className="error-message">{error}</div>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <div className="btn-group">
          <button className="btn btn-secondary" onClick={() => handleSubmit()}>
            <span>OK</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalSelectCereales.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateUserCereales: PropTypes.func.isRequired,
  loadCours: PropTypes.func.isRequired
};

export default connect(
  (state, props) => ({
    user: state.auth && state.auth.utilisateur
  }),
  { updateUserCereales, loadCours }
)(ModalSelectCereales);
