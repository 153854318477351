import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { clearVente, setVente } from '../../store/modules/ui';

import { createContrat } from '../../store/modules/contrats';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalAvertisementPrix = ({ courActuel, clearVente, isOpen, closeModalChangePrix }) => {
  const [error, setError] = useState(null);

  const backModification = () => {
    closeModalChangePrix();
  };
  return (
    <Modal {...modalDefaultClass} id="changement-prix" isOpen={isOpen}>
      <div className="modal-title">
        Changement de prix
        <div
          onClick={() => {
            clearVente();
            setError(null);
          }}
          className="btn-close"
        >
          <i className="fas fa-times" />
        </div>
      </div>
      <div className="modal-body">
        <p>
          Le prix de vente à changée pendant le processus de vente , il est maintenant à{' '}
          {courActuel}€
        </p>
      </div>

      {error && (
        <div className="error-block ">
          <span className="error-icon">
            <i className="fas fa-exclamation" />
          </span>
          <div className="error-message">{error}</div>
        </div>
      )}

      <div className="modal-footer">
        <div className="btn-group">
          <button onClick={() => backModification()} className="btn btn-tertiary">
            <span>Retour</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalAvertisementPrix.propTypes = {
  createContrat: PropTypes.func.isRequired,
  vente: PropTypes.object.isRequired
};

export default connect(
  (state) => ({
    user: state.auth.utilisateur,
    vente: state.data.entities && state.data.entities.ui.vente
  }),
  { createContrat, clearVente, setVente }
)(ModalAvertisementPrix);
