import { flow, set } from 'lodash/fp';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_COURS_REQUEST = 'tdc/FETCH_ALL_COURS_REQUEST';
const FETCH_ALL_COURS_SUCCESS = 'tdc/FETCH_ALL_COURS_SUCCESS';
const FETCH_ALL_COURS_FAILURE = 'tdc/FETCH_ALL_COURS_FAILURE';

export const coursActionsHandlers = {
  [FETCH_ALL_COURS_REQUEST]: (state) =>
    flow(set('loaded.cours', false), set('loading.cours', true))(state),
  [FETCH_ALL_COURS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.cours', action.response.entities.cours || {}),
      set('loaded.cours', true),
      set('loading.cours', false)
    )(state);
  },
  [FETCH_ALL_COURS_FAILURE]: (state) =>
    flow(set('loaded.cours', false), set('loading.cours', false))(state)
};

export function loadCours(clientId) {
  return {
    clientId,
    [CALL_API]: {
      types: [FETCH_ALL_COURS_REQUEST, FETCH_ALL_COURS_SUCCESS, FETCH_ALL_COURS_FAILURE],
      method: 'GET',
      endpoint: `/cours/client/${clientId}`,
      schema: Schemas.COURS_ARRAY,
      successMessage: 'Cours chargés avec succès !'
    }
  };
}
